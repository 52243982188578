import React from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../../components/seo'
import { Container } from '../../utils/styles'

import {BlogPost, Grid, GridLeft, GridRight, BlogMasthead } from './styles';

import BlogList from './list';

const BlogPage = ({ data }) => {
  const blog = data.shopifyArticle

  return (
    <>
      <SEO title={ blog.title } description={ blog.excerpt } image={blog.image} />
      <Container className="extraTop">
        <BlogMasthead>
          <Link to={'/blog'}>Copper Compression</Link>
        </BlogMasthead>
      </Container>
      <Container>
        <Grid>
          <GridLeft>
            <BlogPost>
              <h1 className='title'>{blog.title}</h1>
              <h6>Posted {blog.publishedAt}</h6>
              {blog.image && <img src={blog.image.src} alt={blog.title}/> }
              <div dangerouslySetInnerHTML={{__html:blog.contentHtml}} />
            </BlogPost>
          </GridLeft>
          <GridRight>
            <h2>Recent Blog Posts</h2>
            <BlogList />
          </GridRight>
        </Grid>
      </Container>
    </>
  )
}

export const query = graphql`
  query($id: String!) {
    shopifyArticle(id: { eq: $id }) {
      id
      excerpt
      excerptHtml
      contentHtml
      publishedAt(formatString: "MMMM Do YYYY")
      image {
        src
        id
        altText
        localFile {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
      title
    }
  }
`

export default BlogPage
