import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"

const BlogPostItem = ({post}) => { 

  const filteredUrl = post.url.replace('http://www.coppercompression.com/blogs/news/','/blog/');

  return <Link to={filteredUrl}>{post.title}<br /><span>{post.publishedAt}</span></Link> 

}

export default function BlogList() {
  const data = useStaticQuery(graphql`
    query BlogQuery {
      allShopifyArticle(sort: {fields: publishedAt, order: DESC}) {
        edges {
          node {
            id
            title
            url
            excerptHtml
            publishedAt(formatString: "MMMM Do YYYY")
          }
        }
      }
    }
  `)

  return data.allShopifyArticle.edges.map(post => <BlogPostItem post={post.node} />)

}
