import styled from '@emotion/styled'
import { copper, skyBlue, skyBlue10, oceanBlue } from '../../constants/theme';

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}
export const BlogPost = styled.div`
  color: ${oceanBlue};
  margin-top: 40px;
  h1, h2, h3, h4, h5, h6 {
    font-family: 'HKNova-Bold';
  }
  h1.title {
    color: ${oceanBlue};
    font-size: 20px;
  }
  h1, h5 {
    color: ${copper};
  }
  a {
    color: ${skyBlue};
  }
`

export const Grid = styled.div`
  font-family: 'HKNova-Regular';
  display: flex;
  margin-bottom: 40px;
  @media (max-width: ${breakpoints.l}px){
    flex-direction: column;
  }
  @media (max-width: ${breakpoints.s}px){
    margin-bottom: 0;
  }
`

export const BlogMasthead = styled.div`

  h1, h2, h3, h4, h5, h6 {
  }

  a {
    font-size: 32px;
    font-family: 'HKNova-Bold';
    color: ${oceanBlue};
    text-decoration: none;
    &:hover {
      color: ${skyBlue};
    }
  }

  @media (max-width: ${breakpoints.s}px){
    width: 95%;
    margin: auto;
  }

`;

export const GridLeft = styled.div`
  width: 60%;
  padding-right: 20px;

  img {
    max-width: 100%;
  }

  @media (max-width: ${breakpoints.l}px){
    padding: 0;
    width: 100%;
  }

  @media (max-width: ${breakpoints.s}px){
    width: 95%;
    margin: auto;
  }


`

export const GridRight = styled.div`
  width: 40%;
  padding: 30px;
  margin-left: 40px;
  background-color: ${skyBlue10};
  a {
    display: block;
    color: ${oceanBlue};
    text-decoration: none;
    margin-top: 18px;

    span {
      font-size: 12px;
      color: ${skyBlue};
    }
    &:hover {
      span {
        color: ${oceanBlue};
      }
    }
  }

  @media (max-width: ${breakpoints.l}px){
    padding: 30px;
    box-sizing: border-box;
    margin: 0;
    width: 100%;
    display: block;
  }

  @media (max-width: ${breakpoints.s}px){
    width: 100%;
    padding: 2.5%;
  }



`
